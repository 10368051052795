import { Anchor, BigBtn, Btn, Line } from '../components/styled/ui'
import { Column, Container, Grid, Section } from '../components/styled/layout'
import { Heading, Intro, Title } from '../components/styled/typography'
import { Link, StaticQuery, graphql } from 'gatsby'

import ArrowRight from '../assets/icons/baseline-arrow_forward-16px_black.svg'
import Img from 'gatsby-image'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import Projects from '../components/projects/Projects'
import React from 'react'
import SEO from '../components/SEO/SEO'
import Truncate from 'react-truncate'

const EventPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query KommunikationPageQuery {
          allAirtable(
            filter: {
              table: { eq: "Sidor" }
              data: { Sida: { eq: "Kommunikation" } }
            }
          ) {
            edges {
              node {
                data {
                  Sida

                  Huvudbild {
                    localFiles {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  Uppdrag {
                    data {
                      Titel
                      Rubrik
                      Path
                      Beskrivning {
                        childMarkdownRemark {
                          html
                        }
                      }
                      Bilder {
                        localFiles {
                          childImageSharp {
                            fluid(maxWidth: 900) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const sida = data.allAirtable.edges[0].node.data
        return (
          <React.Fragment>
            <SEO title={'Kommunikationsbyrå'} />
            <Layout>
              <Section>
                <Container>
                  <Grid columns="1fr 1fr">
                    <Column flex col space="space-between">
                      <div>
                        <Title>
                          Kommunikation <span>Ditt budskap. Vår passion.</span>
                        </Title>
                        <p>
                          Vårt uppdrag är att fängsla konsumenter, reta deras
                          sinnen, och väcka deras passion till liv. Du har redan
                          det alla söker. Vår uppgift är att låta världen få
                          veta.
                        </p>
                      </div>
                      <Anchor href="#more" down>
                        Läs mer <ArrowRight />
                      </Anchor>
                    </Column>
                    <Column>
                      <Img
                        fluid={
                          sida.Huvudbild.localFiles[0].childImageSharp.fluid
                        }
                      />
                    </Column>
                  </Grid>
                </Container>
              </Section>
              <Section id="more">
                <Container width="900px">
                  <Column>
                    <Heading>
                      Hur jobbar er verksamhet för att stärka er kommunikation
                      och ert varumärke?
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Verksamhetens varumärke & kommunikation är oändlig. Den
                      innefattar klädsel, personlighet, färger, form och mycket,
                      mycket mer. IKEA har fattat, Apple har fattat.. har du
                      fattat?
                    </Intro>
                    <p>
                      ”Syns du inte, finns du inte”, ett uttryck som de flesta
                      har hört. Räddningstjänsten syd använde det till och med
                      som sin slogan för en kampanj om reflexvästar, genialiskt
                      kan vi tycka. Hur du syns handlar så mycket mer om dina
                      marknadsföringsinsatser, det handlar om att vara tydlig
                      och konsekvent.
                      <br /> <br />
                      Hur kommer det sig att vi kan börja tänka på ett företag
                      genom att enbart se en specifik färg, att höra en viss
                      melodi eller att läsa en specifik mening? Företag som har
                      förstått vikten av att vara konsekventa i sin
                      kommunikation vet också vilken slagkraft det kan ge. Varje
                      detalj i din verksamhet som syns utåt bör återspegla din
                      verksamhets inre. Färg, form, melodi och språk, allt ska
                      kunna kopplas direkt till din verksamhet och ska alltid
                      återspegla verksamhetens värderingar och målgrupp.
                    </p>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Igenkänning</h3>
                        <p>
                          Oavsett om det är en flyer, en kampanj eller en
                          annons. Ditt varumärkes färg, form och stil ska kännas
                          igen direkt.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Tydlighet</h3>
                        <p>
                          Vem ni är och vad ni gör är frågor som åskådaren
                          enkelt ska kunna tyda utifrån er kommunikation.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Trygghet</h3>
                        <p>
                          En verksamhet som är konsekvent och tydlig, är i många
                          fall ett tryggare och enklare val.
                        </p>
                      </Column>
                    </Grid>
                  </Column>
                </Container>
              </Section>
              <Section>
                <Container>
                  <Grid columns="1fr 1fr" nogap>
                    <Column>
                      <Link
                        style={{ height: '100%' }}
                        to={sida.Uppdrag[0].data.Path}
                      >
                        <Img
                          style={{ height: '100%' }}
                          fluid={
                            sida.Uppdrag[0].data.Bilder.localFiles[0]
                              .childImageSharp.fluid
                          }
                        />
                      </Link>
                    </Column>
                    <Column
                      bg="#f4f4f4"
                      space="4vmax"
                      flex
                      col
                      align="flex-start"
                    >
                      Kommunikationsuppdrag
                      <Heading>
                        {sida.Uppdrag[0].data.Rubrik}.{' '}
                        <span>{sida.Uppdrag[0].data.Titel}.</span>
                      </Heading>
                      <Truncate lines={1} ellipsis={'...'}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              sida.Uppdrag[0].data.Beskrivning
                                .childMarkdownRemark.html,
                          }}
                        />
                      </Truncate>
                      <Btn to={sida.Uppdrag[0].data.Path}>
                        Läs mer <ArrowRight />
                      </Btn>
                    </Column>
                  </Grid>
                </Container>
              </Section>
              <Section>
                <Container width="900px">
                  <Column>
                    <Heading>
                      Kommunikation är A och O. Vårt uppdrag är att vara A till
                      Ö.
                    </Heading>
                    <Intro subtle style={{ marginBottom: '3vmax' }}>
                      Vårt uppdrag är att vara din kompletta
                      kommunikationspartner, oavsett ändamål. Vi ser till att du
                      syns, och att du gör det på ett sett som är konsekvent och
                      tydligt.
                    </Intro>
                    <p>
                      Vi besitter ett brett nätverk och en ännu bredare
                      kompetens. Detta gör att vi kan leverera allt från koncept
                      till något konkret, utan att du behöver tänka på något
                      annat bortom resultatet. Vi producerar grafiska
                      identiteter, reklammaterial, marknadsföringsplaner och
                      utvecklar din kommunikation både digitalt och analogt. Med
                      oss som partner har du alltid någon att vända dig till
                      gällande dina kommunikationsbekymmer, någon som lyssnar
                      och som alltid kan leverera konsekventa resultat.
                      <br /> <br />
                      Oavsett om det är något man kan känna på, lukta på, se på
                      eller smaka på. Kanske är det något man kan interagera
                      med, lära sig med eller utvecklas med. Vi älskar att skapa
                      upplevelser, och vi vill att din kommunikation ska bli en
                      unik upplevelse för slutanvändaren.
                    </p>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Grafisk design</h3>
                        <p>
                          Vi tar fram din grafiska identitet. Logotyper, färg
                          och formspråk som återspeglar er. Vi applicerar detta
                          i all tänkbar kommunikation som ni önskar.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Digitala upplevelser</h3>
                        <p>
                          Vi ser potential i den digitala världen. Vi designar
                          och programmerar för att ge unika upplevelser som är
                          roliga, betydelsefulla och målinriktade.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Marknadsföring</h3>
                        <p>
                          Vi ser till att du syns, och vi gör det med din vision
                          och budget i åtanke. Vi kan med traditionell och
                          digital marknadsföring hitta nya kunder till er
                          verksamhet.
                        </p>
                      </Column>
                    </Grid>
                  </Column>
                </Container>
              </Section>
              <Section>
                <Container>
                  <Line />
                  <Heading style={{ marginBottom: '4vmax' }}>
                    Kommunikation. <span>Ett urval av vad vi gjort.</span>
                  </Heading>
                  <Projects limit={4} filter="Kommunikation" />
                </Container>
              </Section>
              <Section>
                <Container mobCover>
                  <Column bg="#f4f4f4" space="5vmax">
                    <Column>
                      <Line />{' '}
                      <Heading>
                        Kommunikationstjänster.{' '}
                        <span>Detta kan vi erbjuda.</span>
                      </Heading>{' '}
                    </Column>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Varumärkesidentitet</h3>
                        <p>
                          Vi älskar att ta fram unika varumärken. Logotyper,
                          formspråk, färgval och typsnitt spelar alla en viktig
                          roll och anpassas efter din målgrupp.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Reklammaterial</h3>
                        <p>
                          Flyers, visitkort, broschyrer, annonser, banners eller
                          rollups. Vi har ett öga för design och hjälper er hela
                          vägen från idé till leverans av produkt.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Webbproduktion</h3>
                        <p>
                          Vi skapar moderna, responsiva hemsidor och digitala
                          upplevelser. Vi jobbar med modern teknik som ökar
                          interaktivitet och säkerhet.
                        </p>
                      </Column>
                    </Grid>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Innehållsproduktion</h3>
                        <p>
                          Innehållsmarknadsföring är en stor och kraftfull
                          marknadsföringsform. Vi skapar unikt innehåll till era
                          sociala medier och digitala kanaler.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Marknadsföring</h3>
                        <p>
                          Vi jobbar med traditionella, moderna och digitala
                          marknadsföringsformer. Vi älskar att hitta unika sätt
                          att fånga människor och förmedla budskap.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Copywriting</h3>
                        <p>
                          Vi skriver engagerande och informativa texter som
                          anpassas utefter den målgrupp som ska konsumera
                          innehållet.
                        </p>
                      </Column>
                    </Grid>
                    <Grid style={{ marginTop: '5vmax' }} columns="1fr 1fr 1fr">
                      <Column>
                        <Line />
                        <h3>Fotografering</h3>
                        <p>
                          Vi fångar minnen, upplevelser och känslor på bild. Vi
                          gör produkter säljande, ansikten mindre främmande och
                          berättar din story genom linsen.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Videoproduktion</h3>
                        <p>
                          Vi kombinerar bild, rörelse, ljud och storytelling för
                          att informera och engagera åskådarna på ett sätt som
                          få andra medier kan åstadkomma.
                        </p>
                      </Column>
                      <Column>
                        <Line />
                        <h3>Motion graphics</h3>
                        <p>
                          Animationer, vinjetter och rörlig grafik i kombination
                          med ljud är en unik mediaform som kan användas för att
                          engagera åskådare på ett unikt sätt.
                        </p>
                      </Column>
                    </Grid>
                    <Section
                      padding="5vmax 0px 0px 0px"
                      style={{
                        borderTop: '1px solid #d8d8d8',
                        marginTop: '5vmax',
                      }}
                    >
                      <Grid columns="2fr 1fr">
                        <Column>
                          <Heading>
                            Ingen uppgift är för stor.{' '}
                            <span>Ingen detalj är för liten.</span>
                          </Heading>
                        </Column>
                        <Column>
                          <Column flex col align="center" justify="center">
                            <BigBtn>Kontakta oss</BigBtn>
                          </Column>
                        </Column>
                      </Grid>
                    </Section>
                  </Column>
                </Container>
              </Section>
            </Layout>
          </React.Fragment>
        )
      }}
    />
  )
}

export default EventPage
